import axios from "axios";
import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import {
  faList,
  faFingerprint,
  faArrowUpRightFromSquare,
  faQrcode,
  faCopy,
  faBarcode,
  faD,
  faW,
  faM,
} from "@fortawesome/free-solid-svg-icons";

import EXLogo from "../components/EXLogo";
import { config } from "../constants";
import { isEmpty, safeCopyToClipboard } from "../helpers";
import NotificationAlert from "../components/Notifications";
import QRModal from "../components/QRModal";

StatCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  icon: PropTypes.object
};

function StatCard({ title, value, icon }) {
  return (
    <div className="card">
      <div className="card-content">
        <div className="card-body">
          <div className="media text-start">
            <div className="media-body">
              <div className="d-flex justify-content-between">
                <div>
                  <h3>{value}</h3>
                  <span>{title}</span>
                </div>
                <div>
                  <FontAwesomeIcon size="3x" icon={icon} className="fas" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Stats() {
  const [statisticsObject, setStatisticsObject] = useState({});
  const [shortUrlObject, setShortUrlObject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFinished, setIsLoadingFinished] = useState(false);
  const [longUrl, setLongUrl] = useState("");
  const [qrContent, setQrContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    status: "",
    message: "",
    type: "primary"
  });

  const { shortUrl } = useParams();
  const urlToCopy = `${config.url.BASE_APP_URL}/${shortUrl}`;

  const handleCopyToClipboard = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    safeCopyToClipboard(urlToCopy);
    setNotificationContent({
      status: "Success!",
      message: `Short url ${urlToCopy} copied to your clipboard`,
      type: "primary",
    });
    setShowNotification(true);
  };

  const handleQRCode = () => {
    setShowModal(true);
    axios
      .post(config.url.QRCODE_API_URL, { shortUrl: shortUrl })
      .then((response) => {
        var qrCodeResponse = `${config.url.BASE_APP_URL}/qr_codes/${response.data.fileName}`;
        setQrContent(qrCodeResponse);
      })
      .catch((error) => {
        setNotificationContent({
          status: "Ooops! Something went wrong...",
          message: error?.message,
        });
        setShowNotification(true);
      });
  };

  function renderNoStats() {
    return (
      <div>
        <p>No stats available for this URL</p>
      </div>
    );
  };

  function showStats() {
    if (isLoadingFinished) {
      if (!isEmpty(statisticsObject) && !isEmpty(shortUrlObject)) {
        return renderStats();
      } else {
        return renderNoStats();
      };
    } else {
      return null;
    };
  };

  useEffect(() => {
    axios.post(config.url.STATS_API_URL, { "shortUrl": shortUrl })
      .then(response => {
        setIsLoadingFinished(true);
        setIsLoading(false);
        if (!isEmpty(response.data.short_url)) {
          setLongUrl(response.data.short_url.long_url);
          setStatisticsObject(response.data.statistics);
          setShortUrlObject(response.data.short_url);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [shortUrl]);

  function renderStats() {
    return (
      <div>
        <div className="row">
          <div>
            <div className="card mt-3 card-links-history">
              <div className="card-body d-flex justify-content-between align-items-center pb-0">
                <p className="card-text card-link-text heading">
                  <strong>
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="fas" />
                  </strong>
                  <a href={urlToCopy} target="_blank" rel="noopener noreferrer"><span>{shortUrl}</span></a>
                </p>
                <p className="card-text card-link-text">
                  <strong>
                    <img src={`https://www.google.com/s2/favicons?domain=${longUrl}`} alt="favicon" />
                  </strong>
                  <span>{longUrl}</span>
                </p>
                <button onClick={handleQRCode} className="btn btn-space" data-toggle="tooltip" data-placement="bottom" title="Generate QR Code">
                  <FontAwesomeIcon icon={faQrcode} className="fas" />
                </button>
                <button onClick={handleCopyToClipboard} className="btn" data-toggle="tooltip" data-placement="bottom" title="Copy to Clipboard">
                  <FontAwesomeIcon icon={faCopy} className="fas" />
                </button>
              </div>
              <div className="ps-3 text-start">
              Created: {shortUrlObject.created_at}
              </div>
            </div>
          </div>
        </div>
        <div className="row py-m-3">
          <div className="col-md-4 py-2 py-sm-2">
            <StatCard icon={faList} title="Total Visits" value={statisticsObject.total_visits} />
          </div>
          <div className="col-md-4 py-2 py-sm-2">
            <StatCard icon={faFingerprint} title="Unique Visitors" value={statisticsObject.unique_visits} />
          </div>
          <div className="col-md-4 py-2 py-sm-2">
            <StatCard icon={faBarcode} title="QR Scan Visits" value={statisticsObject.qr_visits} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 py-2 py-sm-2">
            <StatCard icon={faD} title="Visits Last 24hrs" value={statisticsObject.daily_visits} />
          </div>
          <div className="col-md-4 py-2 py-sm-2">
            <StatCard icon={faW} title="Visits last Week" value={statisticsObject.weekly_visits} />
          </div>
          <div className="col-md-4 py-2 py-sm-2">
            <StatCard icon={faM} title="Visits last Month" value={statisticsObject.monthly_visits} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container>
      <header className="App-header">
        <EXLogo />
      </header>
      <h2>Stat Page for {shortUrl}</h2>
      <div>
        {
          isLoading &&
          <div>
            <Spinner animation="border" variant="primary" />
            <p>Loading Stats...</p>
          </div>
        }
        {showStats()}
      </div>

      < QRModal showModal={showModal} setShowModal={setShowModal} shortUrl={shortUrl} qrContent={qrContent} />

      <div className="row">
        <NotificationAlert
          show={showNotification}
          delay={5000}
          status={notificationContent.status}
          message={notificationContent.message}
          type={notificationContent.type}
          handleToastClose={() => setShowNotification(false)}
        />
      </div>
    </Container>
  );
}

export default Stats;
